import React from 'react';
import { Link, Alert, AlertTitle } from '@mui/material';

interface TemporaryBannerProps {
    name?: string; 
}

const TemporaryBanner: React.FC<TemporaryBannerProps> = ({
    name = "This"
}) => {
    return (
        <Alert severity="warning" style={{ borderBottom: '2px solid #ADD8E6', justifyContent: 'center' }}>
            <AlertTitle>Information</AlertTitle>
            {name} is a temporary service/product. If you have any issues or feature requests,{" "}
            <Link href="https://tideservices.atlassian.net/servicedesk/customer/portal/8/group/15/create/220" color="inherit" target="_blank" rel="noopener noreferrer">
                please click here to raise a ticket
            </Link>.
        </Alert>    );
};

export default TemporaryBanner;
