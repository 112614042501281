import {  Divider, Grid, } from '@mui/material';
import { useEffect } from 'react';
import BannerListItem from '../components/BannerListItem';
import { useBannerContext } from '../data/BannerContext';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}

const AllBanners = (props: Props) => {

    const { banners, setBanners } = useBannerContext();
    const endPoints = useEndpoints();
    const retrieveAllBanner = endPoints.bannerManagerRetrieveAll;

    const fetchItems = async () => {
        try {
            console.log("fetchItems is called");
            const response = await fetch(retrieveAllBanner as RequestInfo, {method: 'GET'});
            if (!response.ok) {
                console.log("response no ok");
            }
            const data = await response.json();
            setBanners(data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []); 
    return(
<Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
                    <Divider style={{marginBottom: "1em", marginTop: "1em" }} />

                    {banners.map(item => (
                        <BannerListItem 
                        key={item.RowKey} 
                        item={item} 
                        fetchItems={fetchItems} 
                    />
                    ))}
                </Grid>
            </Grid>
    );
}

export default AllBanners;