import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { themeOptions } from './geodesysTheme'

import { EventType, AuthenticationResult  } from "@azure/msal-browser";
import { loadEndpoints } from './data/Endpoints';
import { createMsalInstance } from './authConfig';
import { EndpointsProvider } from './utils/EndpointContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme(themeOptions);

loadEndpoints().then(endPoints => {
  const msalInstance = createMsalInstance(endPoints);
  
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE){
        
      }else if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          msalInstance.setActiveAccount(account);
      }else if(event.eventType === EventType.HANDLE_REDIRECT_START){
      }
  });

  root.render(
      <BrowserRouter>
        <EndpointsProvider>
          <ThemeProvider theme={theme}>
            {msalInstance ? <App pca={msalInstance} /> : <>Loading</> }
          </ThemeProvider>
        </EndpointsProvider>
      </BrowserRouter>
  );
}).catch(error => {
  console.error("Failed to load configuration:", error);
});
reportWebVitals();
