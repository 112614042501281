import { AppRoute } from './models/common/AppRoute';
import AllBanners from './pages/AllBanners';
import AddBanner from './pages/AddBanner';
import UpdateBanner from './pages/UpdateBanner';
const AppRoutes : AppRoute[] = [
    {
        path: "/AddBanner",
        name: "Add Banner",
        component: AddBanner,
        isVisible: true
    },
    {
        path: "/AllBanners",
        name: "All Banners",
        component: AllBanners,
        isVisible: true
    },
    {
        path: "/Banners/:qid",
        name: "Update Banner",
        component: UpdateBanner,
        isVisible: false
    }
];

export default AppRoutes;