// BannerContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import GetBannerDto from "../models/common/GetBannerDto"

interface BannerContextType {
    banners: GetBannerDto[];
    setBanners: React.Dispatch<React.SetStateAction<GetBannerDto[]>>;
}

const BannerContext = createContext<BannerContextType | undefined>(undefined);

export const useBannerContext = () => {
    const context = useContext(BannerContext);
    if (!context) {
        throw new Error('useBannerContext must be used within a BannerProvider');
    }
    return context;
};
interface BannerProviderProps {
    children: ReactNode;
}
export const BannerProvider: React.FC<BannerProviderProps> = ({ children }) => {
    const [banners, setBanners] = useState<GetBannerDto[]>([]);

    return (
        <BannerContext.Provider value={{ banners, setBanners }}>
            {children}
        </BannerContext.Provider>
    );
};
