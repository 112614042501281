import React, { useState, ChangeEvent } from 'react';
import { Grid, TextField, Box, Button } from '@mui/material';
import "./AddBanner.css";
import CreateBannerDto from '../models/common/CreateBannerDto';
import { useEndpoints } from '../utils/EndpointContext';

const AddBanner: React.FC = () => {
    const endPoints = useEndpoints();
    const createBanner = endPoints.bannerManagerCreate;
    const [bannerData, setBannerData] = useState<CreateBannerDto>({
        BannerName: '',
        BannerPlacement: '',
        BannerAlignment: '',
        BannerLink: '',
        BannerImageData: ''
    });
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBannerData({ ...bannerData, [event.target.name]: event.target.value });
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
    
            reader.onloadend = () => {
                const fullImageString = reader.result as string;
                const imageString = fullImageString.split(',')[1];
                setBannerData({ ...bannerData, BannerImageData: imageString });
                setSelectedImage(fullImageString); 
            };
    
            reader.readAsDataURL(file); 
        }
    };

    const handleSubmit = async () => {
        if (!bannerData.BannerName || !bannerData.BannerPlacement || !bannerData.BannerAlignment || !bannerData.BannerLink || !bannerData.BannerImageData) {
            alert('Please fill all fields and select an image.');
            return;
        }

        try {
            const payload = JSON.stringify(bannerData);

            const response = await fetch(
                createBanner as RequestInfo, 
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: payload
                }
            );
            
            if (response.ok) {
                setBannerData({
                    BannerName: '',
                    BannerPlacement: '',
                    BannerAlignment: '',
                    BannerLink: '',
                    BannerImageData: ''
                });
                setSelectedImage(null);
                alert('Banner created successfully.');
            } else {
                const responseData = await response.json();
                let errorMessage = `Failed to create banner. Status: ${response.status}`;
                if (responseData) {
                    const respData = JSON.stringify(responseData)
                    errorMessage += `\nError: ${respData}`; 
                }
            alert(errorMessage);
            }
        } catch (error) {
            console.error('Error creating banner:', error);
            alert('An error occurred while creating the banner.');
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Banner Name"
                    fullWidth
                    margin="normal"
                    name="BannerName"
                    value={bannerData.BannerName}
                    onChange={handleInputChange}
                />
               <TextField
                    label="Banner Placement"
                    fullWidth
                    margin="normal"
                    name="BannerPlacement"
                    value={bannerData.BannerPlacement}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Banner Alignment"
                    fullWidth
                    margin="normal"
                    name="BannerAlignment"
                    value={bannerData.BannerAlignment}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Banner Link"
                    fullWidth
                    margin="normal"
                    name="BannerLink"
                    value={bannerData.BannerLink}
                    onChange={handleInputChange}
                />
                <Button 
                    variant="contained" 
                    className='create-banner-button'
                    onClick={handleSubmit}>
                        Create Banner
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box border={1} borderColor="grey.500" borderRadius={2} p={2} minHeight={200} display="flex" justifyContent="center" alignItems="center">
                    {selectedImage ? (
                        <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    ) : (
                        <Button variant="contained" component="label">
                            Upload Image
                            <input type="file" hidden onChange={handleImageChange} />
                        </Button>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default AddBanner;
