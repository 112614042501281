import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { Grid, TextField, Box, Button } from '@mui/material';
import "./UpdateBanner.css";
import { useParams } from 'react-router-dom';
import GetBannerDto from '../models/common/GetBannerDto';
import { useBannerContext } from '../data/BannerContext';
import UpdateBannerDto from '../models/common/UpdateBannerDto';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useEndpoints } from '../utils/EndpointContext';


const UpdateBanner: React.FC = () => {
   const endPoints = useEndpoints();
    const updateBanner = endPoints.bannerManagerUpdate;
    const deleteBanner = endPoints.bannerManagerDelete;
    const [bannerData, setBannerData] = useState<GetBannerDto>({
        RowKey: "",
        BannerName: "",
        BannerPlacement: "",
        BannerAlignment: "",
        BannerLink: "",
        BannerBlobUri: ""
    });
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [bannerImageData, setBannerImageData] = useState('');
    const { banners, setBanners } = useBannerContext();
    const { qid } = useParams<{ qid: string }>();
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const initialBannerDataRef = useRef<GetBannerDto | null>(null);
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isImageUpdated, setIsImageUpdated] = useState(false);
    const handleUpdateImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    
    useEffect(() => {
        const bannerToUpdate = banners.find(banner => banner.RowKey === qid);

        if (bannerToUpdate) {

            setBannerData({
                ...bannerData,
                BannerName: bannerToUpdate.BannerName,
                BannerPlacement: bannerToUpdate.BannerPlacement,
                BannerAlignment: bannerToUpdate.BannerAlignment,
                BannerLink: bannerToUpdate.BannerLink,
                BannerBlobUri: bannerToUpdate.BannerBlobUri,
                RowKey: bannerToUpdate.RowKey
            });
            setSelectedImage(bannerToUpdate.BannerBlobUri);
            initialBannerDataRef.current = bannerToUpdate;
            setIsInitialDataLoaded(true);
        }
    }, [banners, qid, setBanners]);

    useEffect(() => {
        if (isInitialDataLoaded) {
            const hasDataChanged = JSON.stringify(bannerData) !== JSON.stringify(initialBannerDataRef.current);
            setIsButtonDisabled(!(hasDataChanged || isImageUpdated));
        }
    }, [bannerData, isInitialDataLoaded, isImageUpdated]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBannerData({ ...bannerData, [event.target.name]: event.target.value });
    };

    const handleImageChange = (event :any) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
    
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    const base64Data = reader.result.split(',')[1];
                    setBannerImageData(base64Data);
                    setSelectedImage(URL.createObjectURL(file));
                    setIsImageUpdated(true);
                }
            };
    
            reader.readAsDataURL(file);
        }
    };
    
    const handleUpdateBanner = async () => {
        const updateBannerDto: UpdateBannerDto = {
            RowKey: bannerData.RowKey,
            BannerName: bannerData.BannerName,
            BannerPlacement: bannerData.BannerPlacement,
            BannerAlignment: bannerData.BannerAlignment,
            BannerLink: bannerData.BannerLink,
            BannerBlobUri: bannerData.BannerBlobUri,
            BannerImageData: bannerImageData
        };
    
        try {
            const response = await fetch(updateBanner as RequestInfo, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateBannerDto)
            });
    
            if (!response.ok) {
                throw new Error('Failed to update banner');
            }
            navigate(`/AllBanners`);
        } catch (error) {
            console.error('Error updating banner:', error);
        }
    };

    const handleDelete = async (rowKey: string) => {
        try {
            const response = await fetch(deleteBanner as RequestInfo, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: rowKey
            });
        if (response.status === 200) {
            navigate('/AllBanners');
        } else {
            console.error('Error:', response.status);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

    return (
       
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Banner Name"
                    fullWidth
                    margin="normal"
                    name="BannerName"
                    value={bannerData.BannerName}
                    onChange={handleInputChange}
                />
               <TextField
                    label="Banner Placement"
                    fullWidth
                    margin="normal"
                    name="BannerPlacement"
                    value={bannerData.BannerPlacement}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Banner Alignment"
                    fullWidth
                    margin="normal"
                    name="BannerAlignment"
                    value={bannerData.BannerAlignment}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Banner Link"
                    fullWidth
                    margin="normal"
                    name="BannerLink"
                    value={bannerData.BannerLink}
                    onChange={handleInputChange}
                />
                <Button 
                    variant="contained" 
                    className='update-banner-button'
                    onClick={handleUpdateImageClick}>
                        Update Image
                </Button>
                <Button 
                    variant="contained" 
                    disabled={isButtonDisabled}
                    className='update-banner-button'
                    onClick={handleUpdateBanner}>
                        Update Banner
                </Button>
                <Button 
                    variant="contained" 
                    className='update-banner-button delete-button'
                    onClick={() => handleDelete(bannerData.RowKey)} >
                       <FontAwesomeIcon icon={faTrashCan} onClick={() => handleDelete(bannerData.RowKey)} />
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box border={1} borderColor="grey.500" borderRadius={2} p={2} minHeight={200} display="flex" justifyContent="center" alignItems="center">
                    <img src={selectedImage || ''} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
            </Grid>
            <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImageChange}/>
        </Grid>
    );
};

export default UpdateBanner;
